<template>
  <div class="form-container" :class="containerClass">
    <label
      v-if="label && !hideLabel"
      class="form-input-label"
      :class="[
        statusInput,
        customLabelClass,
        requiredInput && 'form-input-label-required',
      ]"
    >
      <slot name="label" v-bind="{ label, tooltip }">
        {{ label }}
        <HccInfoTooltip
          v-if="tooltip"
          :text="tooltip"
          :custom-class="'icon-info__table'"
        />
      </slot>
    </label>
    <div
      class="form-control"
      :class="[
        size,
        statusInput,
        customContainerClass,
        borderLess && 'form-control-border-less',
      ]"
    >
      <button
        v-if="iconInputStart"
        type="button"
        class="icon"
        tabindex="-1"
        :class="{ disabled: disabled }"
        :disabled="disabled"
      >
        <Icon :icon="iconInputStart" class="icon-md" />
      </button>
      <input
        ref="hccInput"
        v-model="value"
        :data-test-id="dataTestId"
        :class="[statusInput, customClass]"
        class="input"
        :type="inputType"
        :readonly="readonly"
        :disabled="disabled"
        :data-test-value="value"
        :maxlength="counterCharacters ? counterCharacters : null"
        v-bind="$attrs"
        @keydown="handleKeypress($event)"
      />
      <div v-if="textIcon" class="icon">
        <p class="text-icon">{{ textIcon }}</p>
      </div>
      <template v-for="action in actions" :key="action.icon">
        <button
          class="icon icon-action-right-icon"
          tabindex="-1"
          :class="[
            action.cursor,
            action.customClass,
            { disabled: disabled || action.disabled },
          ]"
          :style="{ color: `var(${action.color})` }"
          :type="action.buttonType || 'button'"
          :disabled="disabled || action.disabled"
          :data-test-id="action.dataTestId || 'actionButton'"
          @click="actionIcon(action)"
        >
          <Icon
            class="icon-md"
            :class="action.class"
            :icon="
              action.type === 'showPassword' && !showPassword
                ? 'mdi:eye-off'
                : action.icon
            "
          />
        </button>
      </template>
    </div>
    <div
      v-if="helpText || valueCounter"
      class="form-hint overflow-hidden max-w-full"
      :class="statusInput"
      :data-test-id="`${dataTestId || 'input'}Hint`"
    >
      <p v-if="helpText" class="flex-1 overflow-wrap-anywhere">
        {{ helpText }}
      </p>
      <div
        v-if="valueCounter"
        class="counter-box flex-2"
        :class="{ 'counter-characters': counterCharacterStyle }"
      >
        {{ value.length || '0' }} / {{ valueCounter }}
      </div>
    </div>
    <div v-if="errors.length" class="form-input__error">
      <p
        v-for="(error, index) in errors"
        :key="index"
        :data-test-id="`${dataTestId || 'input'}ErrorLabel`"
      >
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, toRef } from 'vue';
import { Icon } from '@iconify/vue';
import HccInfoTooltip from '../HccInfoTooltip/HccInfoTooltip.vue';
import { useField } from 'vee-validate';

const props = defineProps({
  name: { type: String },
  rules: { type: String },
  iconInputStart: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
  },
  hasError: {
    type: [String, Object, Array],
    default: () => [],
  },
  borderLess: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: Boolean,
    default: false,
  },
  textIcon: {
    type: String,
  },
  modelValue: {
    type: [String, Object],
  },
  label: {
    type: String,
  },
  customClass: {
    type: [String, Object, Array],
  },
  customContainerClass: {
    type: [String, Object, Array],
  },
  requiredInput: {
    type: Boolean,
    default: false,
  },
  helpText: {
    type: String,
    default: null,
  },
  tooltip: {
    type: String,
    default: null,
  },
  dataTestId: {
    type: String,
    default: null,
  },
  actions: {
    type: Array,
    default: () => [],
  },
  counterCharacters: {
    type: String,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  containerClass: {
    type: [String, Array],
    default: null,
  },
  customLabelClass: {
    type: [String, Array],
    default: null,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  maxCharacters: {
    type: Number,
    default: null,
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const hccInput = ref(null);
const showPassword = ref(true);
const inputType = ref(props.type);

const initializeField = () => {
  return useField(toRef(props, 'name'), toRef(props, 'rules'), {
    label: () => props.label?.toLowerCase(),
  });
};

const field = props.name ? initializeField() : null;

const value = computed({
  set(val) {
    const lastValue = valueCounter.value ? val.substr(0, valueCounter.value) : val;

    if (field) {
      field.setValue(lastValue);
    }
    emit('update:modelValue', lastValue);
  },
  get() {
    if (field) {
      return field.value.value || '';
    }

    return props.modelValue || '';
  },
});

const statusInput = computed(() => ({
  disabled: props.disabled,
  error: props.error || errors.value.length > 0,
  'read-only': props.readonly,
}));

const handleKeypress = (event) => {
  const allowedKeys = ['Backspace', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab'];
  if (valueCounter.value) {
    const currentLength = event.target.value.length;
    if (
      currentLength >= valueCounter.value &&
      !allowedKeys.includes(event.key)
    ) {
      event.preventDefault();
      return;
    }
  }

  const isNumberInput = event.target.getAttribute('type') === 'number';
  if (isNumberInput) {
    if (minValue.value < 0) {
      allowedKeys.push('-');
    }

    const currentValue = parseFloat(event.target.value + (/[0-9]/i.test(event.key) ? event.key : ''));
    if (!allowedKeys.includes(event.key) && (
      !isValidMinValue(currentValue) ||
      !isValidMaxValue(currentValue) ||
      !/[0-9]/i.test(event.key)
    )) {
      event.preventDefault();
    }
  }
};

const isValidMinValue = (value) => {
  if (minValue.value !== null) {
    return !isNaN(value) && value >= minValue.value;
  }

  return true;
};

const isValidMaxValue = (value) => {
  if (maxValue.value !== null) {
    return !isNaN(value) && value <= maxValue.value;
  }

  return true;
};

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
  inputType.value = showPassword.value ? 'password' : 'text';
};

const actionIcon = (action) => {
  if (action.type === 'showPassword') {
    toggleShowPassword();
    return;
  }
  action.onAction && action.onAction();
};

const minValue = computed(() => {
  if (props.rules?.includes('min_value:')) {
    const ruleMinValue = props.rules.split('|').find((rule) => rule.includes('min_value:'));

    return Number(ruleMinValue.split(':').pop());
  }

  return null;
});

const maxValue = computed(() => {
  if (props.rules?.includes('max_value:')) {
    const ruleMaxValue = props.rules.split('|').find((rule) => rule.includes('max_value:'));

    return Number(ruleMaxValue.split(':').pop());
  }

  return null;
});

const valueCounter = computed(() => {
  if (props.rules?.includes('max:')) {
    const ruleMax = props.rules.split('|').find((rule) => rule.includes('max:'));

    return Number(ruleMax.split(':').pop());
  }

  return props.counterCharacters;
});
const counterCharacterStyle = computed(() => {
  if (valueCounter.value && value.value >= valueCounter.value) {
    return 'counter-characters';
  }

  return '';
});

const errors = computed(() => {
  if (field) {
    return field.errors.value;
  }

  return (props.error ? props.hasError : []) || [];
});

onMounted(() => {
  if (props.autofocus) {
    hccInput.value.focus();
  }
});

const focus = () => hccInput.value?.focus();

defineExpose({ focus });
</script>
